import { SideNavigation } from '@/app/_common/side-navigation'
import { NotFoundPage } from '@/app/not-found'
import { Toaster } from '@/components/ui/toaster'
import { createRootRoute, Outlet } from '@tanstack/react-router'

type SearchParams = {
  prompt?: string
  title?: string
}

const RootRoute = () => (
  <main className='relative flex h-[100dvh] overflow-y-clip font-circular-xx'>
    <SideNavigation />
    <Outlet />
    <Toaster />
  </main>
)

export const Route = createRootRoute({
  notFoundComponent: NotFoundPage,
  validateSearch: () => ({}) as SearchParams,
  component: RootRoute,
})
