import { Button } from '@/components/ui/button'
import { RouteLink } from '@/components/ui/route-link'
import { cn } from '@/lib'
import { useCanGoBack, useRouter } from '@tanstack/react-router'
import { T, useTranslate } from '@tolgee/react'
import { ArrowLeft, ArrowRight } from 'lucide-react'
import React from 'react'

type PageTemplateProps = ComponentProps<{
  title?: React.ReactNode
  icon?: React.ReactNode
}>

function PageTemplate({ children, className, title, icon }: PageTemplateProps) {
  const { t } = useTranslate()
  return (
    <div className='flex w-full flex-col overflow-y-auto bg-content pl-[88px]'>
      {(title || icon) && (
        <header className='flex flex-col justify-between gap-3 border-b border-b-grey-100 px-8 py-4 md:flex-row md:items-center xl:px-20 2xl:px-28'>
          <span className='flex items-center gap-2'>
            {icon}
            <h1 className='pt-[5px] font-neue-machina text-lg font-normal uppercase leading-8 tracking-wide lg:text-xl'>
              {title}
            </h1>
          </span>
          <Button
            asChild
            variant='square-blue'
            className='px-4 py-3 text-sm font-[450] uppercase tracking-wide'
          >
            <RouteLink to='/enterprise-contact' title={t('shared.enterpriseUserButton.a11y')}>
              <T keyName='shared.enterpriseUserButton.title' />
              <ArrowRight className='h-5 w-5' />
            </RouteLink>
          </Button>
        </header>
      )}
      <section className={cn('flex flex-col gap-10 px-8 py-12 xl:px-20 2xl:px-28', className)}>
        {children}
      </section>
    </div>
  )
}

PageTemplate.H1 = function PageTemplateH1({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLHeadingElement>) {
  return (
    <h1
      className={cn('font-neue-machina text-[32px] font-bold leading-10 tracking-wide', className)}
      {...props}
    >
      {children}
    </h1>
  )
}

PageTemplate.H2 = function PageTemplateH2({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLHeadingElement>) {
  return (
    <h2 className={cn('font-neue-machina text-2xl font-bold tracking-wide', className)} {...props}>
      {children}
    </h2>
  )
}

PageTemplate.H3 = function PageTemplateH3({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLHeadingElement>) {
  return (
    <h3
      className={cn('font-neue-machina text-2xl font-normal tracking-wide', className)}
      {...props}
    >
      {children}
    </h3>
  )
}

PageTemplate.BackLink = function PageTemplateBackLink({ className }: { className?: string }) {
  const { t } = useTranslate()
  const router = useRouter()
  const canGoBack = useCanGoBack()
  return (
    <Button
      unstyled
      title={t('shared.backButton.a11y')}
      className={cn(
        'flex w-max items-center gap-2 whitespace-nowrap text-xs font-normal uppercase text-[#252539]',
        className,
      )}
      onClick={() => {
        if (canGoBack) router.history.back()
        else router.navigate({ to: '/' })
      }}
    >
      <ArrowLeft width={16} />
      <T keyName='shared.backButton.title' />
    </Button>
  )
}

export { PageTemplate }
