/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'

// Create Virtual Routes

const PlanLazyImport = createFileRoute('/plan')()
const HistoryLazyImport = createFileRoute('/history')()
const AccountLazyImport = createFileRoute('/account')()
const IndexLazyImport = createFileRoute('/')()
const EnterpriseContactIndexLazyImport = createFileRoute(
  '/enterprise-contact/',
)()
const WorkflowsWorkflowIdLazyImport = createFileRoute(
  '/workflows/$workflowId',
)()
const EnterpriseContactThankYouLazyImport = createFileRoute(
  '/enterprise-contact/thank-you',
)()

// Create/Update Routes

const PlanLazyRoute = PlanLazyImport.update({
  id: '/plan',
  path: '/plan',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/plan.lazy').then((d) => d.Route))

const HistoryLazyRoute = HistoryLazyImport.update({
  id: '/history',
  path: '/history',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/history.lazy').then((d) => d.Route))

const AccountLazyRoute = AccountLazyImport.update({
  id: '/account',
  path: '/account',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/account.lazy').then((d) => d.Route))

const IndexLazyRoute = IndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/index.lazy').then((d) => d.Route))

const EnterpriseContactIndexLazyRoute = EnterpriseContactIndexLazyImport.update(
  {
    id: '/enterprise-contact/',
    path: '/enterprise-contact/',
    getParentRoute: () => rootRoute,
  } as any,
).lazy(() =>
  import('./routes/enterprise-contact/index.lazy').then((d) => d.Route),
)

const WorkflowsWorkflowIdLazyRoute = WorkflowsWorkflowIdLazyImport.update({
  id: '/workflows/$workflowId',
  path: '/workflows/$workflowId',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/workflows.$workflowId.lazy').then((d) => d.Route),
)

const EnterpriseContactThankYouLazyRoute =
  EnterpriseContactThankYouLazyImport.update({
    id: '/enterprise-contact/thank-you',
    path: '/enterprise-contact/thank-you',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/enterprise-contact/thank-you.lazy').then((d) => d.Route),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/account': {
      id: '/account'
      path: '/account'
      fullPath: '/account'
      preLoaderRoute: typeof AccountLazyImport
      parentRoute: typeof rootRoute
    }
    '/history': {
      id: '/history'
      path: '/history'
      fullPath: '/history'
      preLoaderRoute: typeof HistoryLazyImport
      parentRoute: typeof rootRoute
    }
    '/plan': {
      id: '/plan'
      path: '/plan'
      fullPath: '/plan'
      preLoaderRoute: typeof PlanLazyImport
      parentRoute: typeof rootRoute
    }
    '/enterprise-contact/thank-you': {
      id: '/enterprise-contact/thank-you'
      path: '/enterprise-contact/thank-you'
      fullPath: '/enterprise-contact/thank-you'
      preLoaderRoute: typeof EnterpriseContactThankYouLazyImport
      parentRoute: typeof rootRoute
    }
    '/workflows/$workflowId': {
      id: '/workflows/$workflowId'
      path: '/workflows/$workflowId'
      fullPath: '/workflows/$workflowId'
      preLoaderRoute: typeof WorkflowsWorkflowIdLazyImport
      parentRoute: typeof rootRoute
    }
    '/enterprise-contact/': {
      id: '/enterprise-contact/'
      path: '/enterprise-contact'
      fullPath: '/enterprise-contact'
      preLoaderRoute: typeof EnterpriseContactIndexLazyImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexLazyRoute
  '/account': typeof AccountLazyRoute
  '/history': typeof HistoryLazyRoute
  '/plan': typeof PlanLazyRoute
  '/enterprise-contact/thank-you': typeof EnterpriseContactThankYouLazyRoute
  '/workflows/$workflowId': typeof WorkflowsWorkflowIdLazyRoute
  '/enterprise-contact': typeof EnterpriseContactIndexLazyRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexLazyRoute
  '/account': typeof AccountLazyRoute
  '/history': typeof HistoryLazyRoute
  '/plan': typeof PlanLazyRoute
  '/enterprise-contact/thank-you': typeof EnterpriseContactThankYouLazyRoute
  '/workflows/$workflowId': typeof WorkflowsWorkflowIdLazyRoute
  '/enterprise-contact': typeof EnterpriseContactIndexLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexLazyRoute
  '/account': typeof AccountLazyRoute
  '/history': typeof HistoryLazyRoute
  '/plan': typeof PlanLazyRoute
  '/enterprise-contact/thank-you': typeof EnterpriseContactThankYouLazyRoute
  '/workflows/$workflowId': typeof WorkflowsWorkflowIdLazyRoute
  '/enterprise-contact/': typeof EnterpriseContactIndexLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/account'
    | '/history'
    | '/plan'
    | '/enterprise-contact/thank-you'
    | '/workflows/$workflowId'
    | '/enterprise-contact'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/account'
    | '/history'
    | '/plan'
    | '/enterprise-contact/thank-you'
    | '/workflows/$workflowId'
    | '/enterprise-contact'
  id:
    | '__root__'
    | '/'
    | '/account'
    | '/history'
    | '/plan'
    | '/enterprise-contact/thank-you'
    | '/workflows/$workflowId'
    | '/enterprise-contact/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexLazyRoute: typeof IndexLazyRoute
  AccountLazyRoute: typeof AccountLazyRoute
  HistoryLazyRoute: typeof HistoryLazyRoute
  PlanLazyRoute: typeof PlanLazyRoute
  EnterpriseContactThankYouLazyRoute: typeof EnterpriseContactThankYouLazyRoute
  WorkflowsWorkflowIdLazyRoute: typeof WorkflowsWorkflowIdLazyRoute
  EnterpriseContactIndexLazyRoute: typeof EnterpriseContactIndexLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexLazyRoute: IndexLazyRoute,
  AccountLazyRoute: AccountLazyRoute,
  HistoryLazyRoute: HistoryLazyRoute,
  PlanLazyRoute: PlanLazyRoute,
  EnterpriseContactThankYouLazyRoute: EnterpriseContactThankYouLazyRoute,
  WorkflowsWorkflowIdLazyRoute: WorkflowsWorkflowIdLazyRoute,
  EnterpriseContactIndexLazyRoute: EnterpriseContactIndexLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/account",
        "/history",
        "/plan",
        "/enterprise-contact/thank-you",
        "/workflows/$workflowId",
        "/enterprise-contact/"
      ]
    },
    "/": {
      "filePath": "index.lazy.tsx"
    },
    "/account": {
      "filePath": "account.lazy.tsx"
    },
    "/history": {
      "filePath": "history.lazy.tsx"
    },
    "/plan": {
      "filePath": "plan.lazy.tsx"
    },
    "/enterprise-contact/thank-you": {
      "filePath": "enterprise-contact/thank-you.lazy.tsx"
    },
    "/workflows/$workflowId": {
      "filePath": "workflows.$workflowId.lazy.tsx"
    },
    "/enterprise-contact/": {
      "filePath": "enterprise-contact/index.lazy.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
