import { useAuth } from '@/app/_auth/auth-hooks'
import { TIER_IDS } from './plan-features'

export type TierID = 'Free' | 'Enterprise'

type StaticTierData = { tierId: TierID; tierIndex: number }

const STATIC_TIER_DATA: Record<TierID, StaticTierData> = {
  Free: {
    tierId: 'Free',
    tierIndex: 0,
  },
  Enterprise: {
    tierId: 'Enterprise',
    tierIndex: 1,
  },
}

type UseTierDataReturn = StaticTierData & { isCurrent: boolean; isDowngrade: boolean }

/**
 * Returns the tier data for the provided TierID or the user's current tier if no TierID is provided.
 * @param tierId
 * @returns
 */
export function useTierData({ tierId }: { tierId?: TierID } = {}): UseTierDataReturn {
  const { user } = useAuth()

  if (!user) throw new Error('USER_NOT_LOGGED_IN')

  const currentTier = STATIC_TIER_DATA[user.tier]

  // if no TierID is provided return the current tier
  if (!tierId) return { ...currentTier, isCurrent: true, isDowngrade: false }

  const targetTier = STATIC_TIER_DATA[tierId]

  return {
    ...targetTier,
    isCurrent: currentTier.tierId === targetTier.tierId,
    isDowngrade: currentTier.tierIndex > targetTier.tierIndex,
  }
}

export function tierIdTypeGuard(tierId?: string): tierId is TierID {
  if (!tierId) return false

  return TIER_IDS.includes(tierId as TierID)
}
