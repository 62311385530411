// 👇 components

import { useTierData, type TierID } from '@/app/plan/_common/use-tier-data'
import { Button } from '@/components/ui/button'
import { cn } from '@/lib'
import { T, useTranslate, type TranslationKey } from '@tolgee/react'
import { cva, type VariantProps } from 'class-variance-authority'
import { CheckCircle2, XCircle } from 'lucide-react'
import React from 'react'

type PlanHeaderProps = Readonly<{
  className?: string
  tierId: TierID
  actionButton?: React.ReactNode
  offsetCol?: PlanSectionVariantProps['offsetCol']
}>

export const PlanHeader = ({ className, tierId, offsetCol, actionButton }: PlanHeaderProps) => {
  const { t } = useTranslate()
  const tierData = useTierData({ tierId })
  const { titleKey, descriptionKey } = TIER_HEADER_DATA[tierId]
  return (
    <div
      className={cn(
        'flex flex-col justify-between gap-8 border pb-10 pt-4',
        planSectionVariants({ selected: tierData.isCurrent, offsetCol, rounded: 'top' }),
        className,
      )}
    >
      <div className='flex flex-col gap-2'>
        <h2 className='font-neue-machina text-xl font-light xl:text-2xl'>
          <T keyName={titleKey} />
        </h2>
        <span className='text-sm'>
          <T keyName={descriptionKey} />
        </span>
      </div>
      {tierData.isCurrent ? (
        <Button
          disabled
          title={t('plan.currentPlan')}
          className='w-full text-xs uppercase xl:text-base'
        >
          <T keyName='plan.currentPlan' />
        </Button>
      ) : (
        actionButton
      )}
    </div>
  )
}

// 👇 components

type FeatureRowProps = Readonly<{
  featureId: FeatureCategoryID
  rounded?: 'bottom'
}>

export const FeatureRow = (props: FeatureRowProps) => (
  <>
    <div className='hidden w-full justify-end py-2 text-right font-neue-machina text-sm font-normal xl:flex'>
      <T keyName={TIER_FEATURE_DATA[props.featureId].titleKey} />
    </div>
    {TIER_IDS.map((tierId, index) => (
      <PlanFeatureCategory key={tierId} tierId={tierId} offsetCol={index === 0} {...props} />
    ))}
  </>
)

function PlanFeatureCategory({
  tierId,
  featureId,
  rounded,
  offsetCol,
}: Readonly<
  {
    tierId: TierID
    offsetCol?: boolean
  } & FeatureRowProps
>) {
  const { isCurrent } = useTierData({ tierId })
  const data = TIER_FEATURE_DATA[featureId]
  const listData = data.tier[tierId]
  return (
    // tier features cell
    <div
      key={tierId}
      className={planSectionVariants({
        rounded,
        selected: isCurrent,
        offsetCol,
        className: 'border-x border-b py-2',
      })}
    >
      <div className='mb-2 font-neue-machina text-sm font-normal xl:mb-0 xl:hidden'>
        <T keyName={data.titleKey} />
      </div>
      {listData.length === 0 ? (
        // feature not included
        <ul className='mb-2 list-decimal'>
          <li className='flex items-start gap-1 text-sm md:gap-2 md:text-base'>
            <XCircle strokeWidth={2} className='w-4 shrink-0' color='#000' />
            <T keyName='plan.notIncluded' />
          </li>
        </ul>
      ) : (
        listData.map((list) => (
          // features list
          <div
            key={`${featureId}-${list.titleKey}-${tierId}`}
            className='[&:not(:last-child)]:mb-2'
          >
            {list.titleKey && (
              <h3 className='font-bold'>
                <T keyName={list.titleKey} />
              </h3>
            )}
            <ul className='list-decimal'>
              {list.featureKeys.map((featureKey) => (
                <li
                  key={featureKey}
                  className='flex items-start gap-1 text-sm md:gap-2 md:text-base'
                >
                  <CheckCircle2 strokeWidth={2} className='w-4 shrink-0' color='#000' />
                  <T keyName={featureKey} />
                </li>
              ))}
            </ul>
          </div>
        ))
      )}
    </div>
  )
}

// 👇 className variants

type PlanSectionVariantProps = VariantProps<typeof planSectionVariants>
const planSectionVariants = cva('col-span-2 border-section-border px-4', {
  variants: {
    selected: {
      true: 'bg-white',
      false: 'bg-section',
    },
    offsetCol: {
      true: 'xl:col-start-2',
    },
    rounded: {
      top: 'rounded-t-2xl',
      bottom: 'rounded-b-2xl',
    },
  },
  defaultVariants: {
    selected: false,
  },
})

// 👇 types

export type FeatureCategoryID =
  | 'requestProcessing'
  | 'capabilities'
  | 'interfaces'
  | 'rateLimits'
  | 'support'
  | 'infrastructure'
  | 'observability'

// 👇 static data

export const FEATURE_CATEGORY_IDS: FeatureCategoryID[] = [
  'capabilities',
  'interfaces',
  'requestProcessing',
  'rateLimits',
  'support',
  'infrastructure',
  'observability',
]

export const TIER_IDS: TierID[] = ['Free', 'Enterprise']

const TIER_HEADER_DATA: Record<
  TierID,
  { titleKey: TranslationKey; descriptionKey: TranslationKey }
> = {
  Free: {
    titleKey: 'plan.free.title',
    descriptionKey: 'plan.free.description',
  },
  Enterprise: {
    titleKey: 'plan.enterprise.title',
    descriptionKey: 'plan.enterprise.description',
  },
}

const TIER_FEATURE_DATA: Record<
  FeatureCategoryID,
  {
    titleKey: TranslationKey
    tier: Record<
      TierID,
      {
        titleKey?: TranslationKey
        featureKeys: TranslationKey[]
      }[]
    >
  }
> = {
  requestProcessing: {
    titleKey: 'plan.planSectionTitles.requestProcessing',
    tier: {
      Free: [{ featureKeys: ['plan.shared.requestProcessing.list.0'] }],
      Enterprise: [
        {
          featureKeys: [
            'plan.enterprise.requestProcessing.list.0',
            'plan.enterprise.requestProcessing.list.1',
          ],
        },
      ],
    },
  },
  capabilities: {
    titleKey: 'plan.planSectionTitles.capabilities',
    tier: {
      Free: [
        {
          titleKey: 'plan.shared.capabilities.workflows.title',
          featureKeys: [
            'plan.shared.capabilities.workflows.list.0',
            'plan.shared.capabilities.workflows.list.1',
          ],
        },
        {
          titleKey: 'plan.shared.capabilities.skills.title',
          featureKeys: [
            'plan.shared.capabilities.skills.list.0',
            'plan.shared.capabilities.skills.list.1',
            'plan.shared.capabilities.skills.list.2',
          ],
        },
      ],
      Enterprise: [
        {
          titleKey: 'plan.enterprise.capabilities.solutions.title',
          featureKeys: [
            'plan.enterprise.capabilities.solutions.list.0',
            'plan.enterprise.capabilities.solutions.list.1',
            'plan.enterprise.capabilities.solutions.list.2',
            'plan.enterprise.capabilities.solutions.list.3',
          ],
        },
        {
          titleKey: 'plan.enterprise.capabilities.workflows.title',
          featureKeys: [
            'plan.enterprise.capabilities.workflows.list.0',
            'plan.enterprise.capabilities.workflows.list.1',
            'plan.enterprise.capabilities.workflows.list.2',
            'plan.enterprise.capabilities.workflows.list.3',
            'plan.enterprise.capabilities.workflows.list.4',
          ],
        },
        {
          titleKey: 'plan.shared.capabilities.skills.title',
          featureKeys: [
            'plan.shared.capabilities.skills.list.0',
            'plan.shared.capabilities.skills.list.1',
            'plan.shared.capabilities.skills.list.2',
          ],
        },
      ],
    },
  },
  interfaces: {
    titleKey: 'plan.planSectionTitles.interfaces',
    tier: {
      Free: [{ featureKeys: ['plan.shared.interfaces.list.0', 'plan.shared.interfaces.list.1'] }],
      Enterprise: [
        { featureKeys: ['plan.shared.interfaces.list.0', 'plan.shared.interfaces.list.1'] },
      ],
    },
  },
  rateLimits: {
    titleKey: 'plan.planSectionTitles.rateLimits',
    tier: {
      Free: [{ featureKeys: ['plan.free.rateLimits.list.0'] }],
      Enterprise: [{ featureKeys: ['plan.enterprise.rateLimits.list.0'] }],
    },
  },
  support: {
    titleKey: 'plan.planSectionTitles.support',
    tier: {
      Free: [],
      Enterprise: [
        {
          featureKeys: [
            'plan.enterprise.support.list.0',
            'plan.enterprise.support.list.1',
            'plan.enterprise.support.list.2',
            'plan.enterprise.support.list.3',
          ],
        },
      ],
    },
  },
  infrastructure: {
    titleKey: 'plan.planSectionTitles.infrastructure',
    tier: {
      Free: [{ featureKeys: ['plan.shared.infrastructure.list.0'] }],
      Enterprise: [
        {
          featureKeys: [
            'plan.enterprise.infrastructure.list.0',
            'plan.enterprise.infrastructure.list.1',
          ],
        },
      ],
    },
  },
  observability: {
    titleKey: 'plan.planSectionTitles.observability',
    tier: {
      Free: [],
      Enterprise: [{ featureKeys: ['plan.enterprise.observability.list.0'] }],
    },
  },
}
