import { toast } from '@/components/ui/toaster'
import { type ClassValue, clsx } from 'clsx'
import copyToClipboard from 'copy-to-clipboard'
import dayjs from 'dayjs'
import DurationPlugin from 'dayjs/plugin/duration'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

dayjs.extend(DurationPlugin)
dayjs.extend(LocalizedFormat)

export function formatDate(
  date: number | string,
  options: { template?: string } = { template: 'LL' },
) {
  const dateNumber = typeof date === 'string' ? parseInt(date) : date

  return dayjs(dateNumber * 1000).format(options.template)
}

export function getDateDiffDuration({
  startEpoch,
  endEpoch,
}: {
  startEpoch?: number
  endEpoch?: number
}) {
  if (!startEpoch || !endEpoch) return '00:00:00'

  const diff = dayjs(endEpoch * 1000).diff(startEpoch * 1000)

  return dayjs.duration(diff).format('HH:mm:ss')
}

/**
 * Copies the provided text to the clipboard and displays a toast notification.
 *
 * @param {string} [params.text] - The text to be copied to the clipboard.
 * @param {string} params.errorMessage - The message to display if copying fails.
 * @param {string} params.successMessage - The message to display if copying succeeds.
 */
export function copy({
  text,
  errorMessage,
  successMessage,
}: {
  text?: string
  errorMessage: string
  successMessage: string
}) {
  const success = !!text && copyToClipboard(text)

  if (success) toast.success(successMessage)
  else toast.error(errorMessage)
}
