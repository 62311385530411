import { inputVariants } from '@/components/ui/input'
import { cn } from '@/lib'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

export type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & TextAreaVariants
export const Textarea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, variant, error, ...props }, ref) => {
    return (
      <textarea
        tabIndex={0}
        className={cn(textAreaVariants({ variant, error }), className)}
        ref={ref}
        {...props}
      />
    )
  },
)
Textarea.displayName = 'Textarea'

type TextAreaVariants = VariantProps<typeof textAreaVariants>
const textAreaVariants = cva('', {
  variants: {
    variant: {
      input: [inputVariants({ height: 'auto', text: 'md' }), 'px-3 py-2'],
      gray: [inputVariants({ variant: 'gray', height: 'auto', text: 'md' }), 'px-3 py-2'],
      inputError: [inputVariants({ error: true, height: 'auto', text: 'md' }), 'px-3 py-2'],
      prompt:
        'bg-grey-300 p-10 text-xl xl:text-2xl xl:leading-[32px] tracking-wide w-full font-neue-machina outline-none text-grey-600 font-normal rounded-2xl',
    },
    error: {
      true: inputVariants({ error: true, variant: 'gray', height: 'auto', text: 'md' }),
    },
  },
  defaultVariants: {
    variant: 'input',
  },
  compoundVariants: [
    { variant: 'gray', error: false, className: 'border-[#AEA9B4] focus:outline-focus' },
  ],
})
